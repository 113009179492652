<template>
    <li>
        <a v-if="!menuItem.image || menuItem.image.length === 0" :href="menuItem.url" class="menu-item">{{menuItem.title}}</a>
        <a v-else-if="menuItem.image" :href="menuItem.url" class="menu-item">
            <img :src="menuItem.image" :alt="menuItem.title" class="menu-image"/>
            <span class="menu-image-text">
                {{menuItem.title}}
            </span>
        </a>
        <ul v-if="menuItem.children && menuItem.children && menuItem.children.length" class="menu-item-children">
            <MenuItem v-for="( item, index ) in Object.values( menuItem.children )" v-bind:key="index"
                :menu-item="item"
            />
        </ul>
    </li>
</template>

<script>

export default {
    name: 'MenuItem',
    components:{
        MenuItem: () => import( './MenuBlockItem.vue' )
    },
    props: {
        menuItem:{
            type: Object,
            default: () => {}
        },
    },
};
</script>

<style lang="scss" scoped>
    ul.menu-item-children{
        list-style-type:none;
        padding-inline-start: 30px;
    }
</style>
